var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let BusinessList = class BusinessList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                title: t("management.businesses.fields.name"),
                key: "management.businesses.fields.name",
                dataIndex: ["name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address",
                title: t("management.businesses.fields.address"),
                key: "management.businesses.fields.address",
                dataIndex: ["address"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "latitude",
                title: t("management.businesses.fields.latitude"),
                key: "management.businesses.fields.latitude",
                dataIndex: ["latitude"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "longitude",
                title: t("management.businesses.fields.longitude"),
                key: "management.businesses.fields.longitude",
                dataIndex: ["longitude"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "description",
                title: t("management.businesses.fields.description"),
                key: "management.businesses.fields.description",
                dataIndex: ["description"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phoneNumber",
                title: t("management.businesses.fields.phoneNumber"),
                key: "management.businesses.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.facebook",
                title: t("management.businesses.fields.socialMediaLinks.facebook"),
                key: "management.businesses.fields.socialMediaLinks.facebook",
                dataIndex: ["socialMediaLinks", "facebook"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.twitter",
                title: t("management.businesses.fields.socialMediaLinks.twitter"),
                key: "management.businesses.fields.socialMediaLinks.twitter",
                dataIndex: ["socialMediaLinks", "twitter"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.linkedin",
                title: t("management.businesses.fields.socialMediaLinks.linkedin"),
                key: "management.businesses.fields.socialMediaLinks.linkedin",
                dataIndex: ["socialMediaLinks", "linkedin"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.instagram",
                title: t("management.businesses.fields.socialMediaLinks.instagram"),
                key: "management.businesses.fields.socialMediaLinks.instagram",
                dataIndex: ["socialMediaLinks", "instagram"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "accountPaused",
                title: t("management.businesses.fields.accountPaused"),
                key: "management.businesses.fields.accountPaused",
                dataIndex: ["accountPaused"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isHold",
                title: t("management.businesses.fields.isHold"),
                key: "management.businesses.fields.isHold",
                dataIndex: ["isHold"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isViewed",
                title: t("management.businesses.fields.isViewed"),
                key: "management.businesses.fields.isViewed",
                dataIndex: ["isViewed"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "numberOfCredits",
                title: t("management.businesses.fields.numberOfCredits"),
                key: "management.businesses.fields.numberOfCredits",
                dataIndex: ["numberOfCredits"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "leadCredits",
                title: t("management.businesses.fields.leadCredits"),
                key: "management.businesses.fields.leadCredits",
                dataIndex: ["leadCredits"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "coin",
                title: t("management.businesses.fields.coin"),
                key: "management.businesses.fields.coin",
                dataIndex: ["coin"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "accountType",
                title: t("management.businesses.fields.accountType"),
                key: "management.businesses.fields.accountType",
                dataIndex: ["accountType"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "enum",
                                    value,
                                    labelify: true,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "requestedCourses",
                title: t("management.businesses.fields.requestedCourses"),
                key: "management.businesses.fields.requestedCourses",
                dataIndex: ["requestedCourses"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "stripeCustomerId",
                title: t("management.businesses.fields.stripeCustomerId"),
                key: "management.businesses.fields.stripeCustomerId",
                dataIndex: ["stripeCustomerId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isOnboarded",
                title: t("management.businesses.fields.isOnboarded"),
                key: "management.businesses.fields.isOnboarded",
                dataIndex: ["isOnboarded"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.businesses.fields.createdAt"),
                key: "management.businesses.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.businesses.fields.updatedAt"),
                key: "management.businesses.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.businesses.fields.createdById"),
                key: "management.businesses.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.businesses.fields.updatedById"),
                key: "management.businesses.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "owner",
                title: t("management.businesses.fields.owner"),
                key: "management.businesses.fields.owner",
                dataIndex: ["owner"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "logo",
                title: t("management.businesses.fields.logo"),
                key: "management.businesses.fields.logo",
                dataIndex: ["logo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.IMAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "country",
                title: t("management.businesses.fields.country"),
                key: "management.businesses.fields.country",
                dataIndex: ["country"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.COUNTRIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "timezone",
                title: t("management.businesses.fields.timezone"),
                key: "management.businesses.fields.timezone",
                dataIndex: ["timezone"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TIMEZONES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "subscription",
                title: t("management.businesses.fields.subscription"),
                key: "management.businesses.fields.subscription",
                dataIndex: ["subscription"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESS_SUBSCRIPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "stripeSubscriptionId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "addon",
                title: t("management.businesses.fields.addon"),
                key: "management.businesses.fields.addon",
                dataIndex: ["addon"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.BUSINESS_ADDONS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "stripeAddonSubscriptionId",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "calendar",
                title: t("management.businesses.fields.calendar"),
                key: "management.businesses.fields.calendar",
                dataIndex: ["calendar"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CALENDARS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "todosList",
                title: t("management.businesses.fields.todosList"),
                key: "management.businesses.fields.todosList",
                dataIndex: ["todosList"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TODOS_LISTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            owner: "owner.fullName",
            logo: "logo._id",
            country: "country._id",
            timezone: "timezone._id",
            subscription: "subscription.stripeSubscriptionId",
            addon: "addon.stripeAddonSubscriptionId",
            calendar: "calendar._id",
            todosList: "todosList._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            address: 1,
            latitude: 1,
            longitude: 1,
            description: 1,
            phoneNumber: 1,
            socialMediaLinks: {
                facebook: 1,
                twitter: 1,
                linkedin: 1,
                instagram: 1,
            },
            accountPaused: 1,
            isHold: 1,
            isViewed: 1,
            numberOfCredits: 1,
            leadCredits: 1,
            coin: 1,
            accountType: 1,
            requestedCourses: {
                courseId: 1,
                endDate: 1,
            },
            stripeCustomerId: 1,
            isOnboarded: 1,
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            country: {
                _id: 1,
            },
            countryId: 1,
            timezone: {
                _id: 1,
            },
            timezoneId: 1,
            subscription: {
                _id: 1,
                stripeSubscriptionId: 1,
            },
            subscriptionId: 1,
            addon: {
                _id: 1,
                stripeAddonSubscriptionId: 1,
            },
            addonIds: 1,
            calendar: {
                _id: 1,
            },
            calendarId: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
        };
    }
};
BusinessList = __decorate([
    Service({ transient: true })
], BusinessList);
export { BusinessList };
